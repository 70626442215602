import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const TIER1 = '';

class Membership extends Component {

    constructor(props) {
        super(props);
        this.state = {
            discordID: '',
            stripe: null
        };
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get("session_id")) {
            this.setState({sent: 'Success! Once your payment is confirmed you will receive an email and your account will be upgraded.'}, () => {
                
            });
        }
        if (urlParams.get("cancelled")) {
            this.setState({sent: 'Payment cancelled.'}, () => {
                
            });
        }
        if (this.props.currentUser.isAuthenticated) {
            this.props.getUser('refreshtoken', this.state)
            .then(() => {
            })
            .catch(error => {
                console.log(error)
                this.setState({sent: null}, () => {
                    this.setState({errors: error}, () => {
                        console.log(error)
                        return;
                    });
                });
            })
        }
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    // handleDiscordID = e => {
    //     e.preventDefault();
    //     const authType = 'updatediscord';
    //     this.props.updateUser(authType, this.state)
    //     .then(() => {
    //         this.setState({sent: 'Discord synced with application. If you are a member, your profile will be upgraded automatically on the Discord Server.'}, () => {
    //             return;
    //         });
    //     })
    //     .catch(error => {
    //     });
    // }

    handleCancelSubscription = e => {
        e.preventDefault();
        const authType = 'cancelsubscription';
        this.props.updateUser(authType, this.state)
        .then(() => {
            this.setState({sent: 'Cancelled Subscription Renewal.'}, () => {
                return;
            });
        })
        .catch(error => {
            this.setState({sent: null}, () => {
                this.setState({errors: error}, () => {
                    return;
                });
            });
        });
    }

    handleSubscriptionSession = e => {
        e.preventDefault();
        this.props.apiCall('post', '/subscribesession', this.state)
        .then((data) => {
            window.location.href = data.url;
        })
        .catch(error => {
            this.setState({sent: null}, () => {
                this.setState({errors: error}, () => {
                    return;
                });
            });
        });
    }

    render() {
        if (!this.props.currentUser.isAuthenticated) {
            return (<Redirect to={{ pathname: '/login' }} />);
        } 
        const { errors, history, removeError } = this.props;
        history.listen(() => {
            removeError();
        });
        let subEndDate = this.props.currentUser.currentSubscriptionEndDate;
        let hasSubEndDate = this.props.currentUser.currentSubscriptionEndDate !== TIER1;
        console.log(this.props.currentUser.currentSubscriptionEndDate)
        let isRecurring = this.props.currentUser.isRecurring;
        let membership = hasSubEndDate ? 'Premium - Tier 2' : 'Basic - Free Tier';
        
        return (
            <div id='accountsettings' class='single-bar-chart-wrapper'>
                <div className="account_settings_container">
                    <div className="content">
                    <h1>Membership Settings</h1>
                        {this.state.errors && (
                            <div className='alert alert-danger'>{this.state.errors.msg}</div>
                        )}
                        {this.state.sent && (
                            <div className='alert alert-success'>{this.state.sent}</div>
                        )}
                        <form onSubmit={this.handleSubscription}>
                            <div className="form-group">
                                <label htmlFor="username">Your Membership</label>
                                <hr></hr>
                                <div>
                                <hr></hr>
                                    {hasSubEndDate ? (
                                        <div>
                                            {isRecurring ? (
                                                <div>
                                                    <h6>Renews On : {subEndDate}</h6>
                                                </div>
                                            ) : (<div><h6>Expires On : {subEndDate}</h6></div>)}
                                        </div>
                                    ) : (<div></div>)}
                                </div>
                                <hr></hr>
                                <input
                                    autoComplete="off"
                                    className="form-control"
                                    type="text"
                                    value={membership}
                                    readonly
                                    disabled
                                ></input>
                            </div>
                            {hasSubEndDate ? (
                                <div>
                                    {isRecurring ? (
                                        <div className="form-group">
                                            <button
                                                onClick={this.handleCancelSubscription}
                                                id='deleteaccount'
                                                type="submit"
                                                className="btn">
                                                Cancel Renewal
                                            </button>
                                        </div>
                                    ) : (<div></div>)}
                                </div>
                            ) : (
                            <div>
                                <hr></hr>
                                <div className="form-group">
                                    <button
                                        onClick={this.handleSubscriptionSession}
                                        type="submit"
                                        className="btn">
                                        Become a Member - $249.99/MONTH
                                    </button>
                                </div>
                            </div>)}
                            <hr></hr>
                        </form>
                        {/* <form onSubmit={this.handleDiscordID}>
                            <div className="form-group">
                                <label htmlFor="Discord ID">Sync Discord ID<a target='_blank' href="https://www.remote.tools/remote-work/how-to-find-discord-id"> (Instructions Here)</a></label>
                                <hr></hr>
                                <label>Ensure you have joined and verified in the Discord. Once you sync your ID you cannot change it!</label>
                                <hr></hr>
                                {this.props.currentUser.discordID.length === 0 ? (
                                    <input
                                        autoComplete="off"
                                        className="form-control"
                                        id="discordID"
                                        name="discordID"
                                        type="text"
                                        placeholder='E.g. (123456)'
                                        onChange={this.handleChange}
                                        required
                                    ></input>
                                ) : (<input
                                        autoComplete="off"
                                        className="form-control"
                                        id="discordID"
                                        name="discordID"
                                        type="text"
                                        placeholder={this.props.currentUser.discordID}
                                        onChange={this.handleChange}
                                        readonly
                                        disabled
                                    ></input>)}
                            </div>
                            <hr></hr>
                            {this.props.currentUser.discordID.length === 0 ? (
                                <div className="form-group">
                                    <button
                                        type="submit"
                                        className="btn">
                                        Sync Discord ID
                                    </button>
                                </div>
                            ) : (<div></div>)}
                        </form> */}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        currentUser : state.currentUser
    };
}

export default connect(mapStateToProps, {  })(Membership);