import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            sent: false
        };
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSubmit = e => {
        e.preventDefault();
        const authType = 'resetpasswordrequest';
        this.props.onAuth(authType, this.state)
            .then(() => {
                this.setState({sent: 'You will receive an email if the account exists'}, () => {
                    return;
                });
            })
            .catch(() => {
                this.setState({sent: null}, () => {
                });
            });
    }

    render() {
        const { errors, history, removeError } = this.props;
        history.listen(() => {
            removeError();
        });

        return (
            <div className="login_container">
                <div className="image">
                    <h1>Forgot Password</h1>
                </div>
                <div className="content">
                    <form onSubmit={this.handleSubmit}>
                        <h1>Enter Your Email</h1>
                        {errors.message && (
                            <div className='alert alert-danger'>{errors.message}</div>
                        )}
                        {this.state.sent && (
                            <div className='alert alert-success'>{this.state.sent}</div>
                        )}
                        <div className="form-group">
                            <label htmlFor="email">E-mail</label>
                            <br></br>
                            <input
                                autoComplete="off"
                                className="form-control"
                                id="email"
                                name="email"
                                type="email"
                                required
                                onChange={this.handleChange}
                            ></input>
                        </div>
                        <hr></hr>
                        <Link className="fp" to="/login">Return To Login</Link>
                        <hr></hr>
                        <div className="form-group">
                            <button
                                type="submit"
                                className="btn">
                                Submit                                
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default ResetPassword;