import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

ChartJS.defaults.color = "#ffffff";
ChartJS.defaults.plugins.legend.onHover = function() { 
  document.body.style.cursor = 'pointer'; 
};
ChartJS.defaults.plugins.legend.onLeave = function() { 
  document.body.style.cursor = 'unset'; 
};

const indices = {
  'S&P500': 'S&P 500',
  'NQ100': 'Nasdaq',
  'DOW30': 'Dow Jones Industrial Average',
}

const plugin = {
  id: 'customCanvasBackgroundColor',
  beforeDraw: (chart, args, options) => {
    const {ctx} = chart;
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = options.color || '#0';
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  }
};

const options = {
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  stacked: false,
  elements: {
    point: {
      radius: 0.5
    }
  },
  layout: {
      padding: 5
  },
  scales: {
    x: {
      ticks: {
        maxTicksLimit: 9
      }
    },
    y: {
      type: 'linear',
      display: true,
      position: 'left',
    },
    y1: {
      type: 'linear',
      display: true,
      position: 'right',
      grid: {
        drawOnChartArea: false,
      },
    },
  },
  plugins: {
    legend: {
      position: 'top',
    },
    tooltip: {
      mode: 'nearest',
      intersect: false
    },
    title: {
      display: true,
      text: 'Dark Pool Index',
      color: 'white',
      font: {
        size: 25,
        family: 'Quicksand',
        lineHeight: 1
      },
    },
    zoom: {
      zoom: {
        wheel: {
          enabled: true,
        },
        pinch: {
          enabled: true
        },
        mode: 'x',
      },
      pan: {
        enabled: true,
        mode: 'x',
      },
    }
  },
};

const CHARTPADDING = 50;
const TIER1 = '';

class DarkPool extends Component {
  
    constructor(props) {
        super(props);
        this.state = {
          data : []
        }
    }

    componentDidMount() {
      if (!this.props.currentUser.isAuthenticated || this.props.currentUser.currentSubscriptionEndDate === TIER1) {
        return;
      }
      this.props.apiCall('get', '/darkpoolandgammaexposure', this.state)
      .then((dixgexdata) => {
        for (const [key, data] of Object.entries(dixgexdata)) {
          let chartData = {labels : [], indexValues: [], dixValues : []}
          let indexData = data.indexValue.split(',');
          let dixData = data.dix.split(',');
          indexData.pop();
          dixData.pop();
          for (let i = 0; i < indexData.length; i++) {
            if (indexData.length === dixData.length) {
              let indexPair = indexData[i].split(':');
              let dixPair = dixData[i].split(':');
              chartData.labels.push(indexPair[0]);
              chartData.indexValues.push(indexPair[1]);
              chartData.dixValues.push(dixPair[1]);
            }
          }
          for (let i = 0; i < CHARTPADDING; i++) {
            chartData.labels.push(' ');
          }
          let title = data['index'];
          this.setState({data: [...this.state.data, {data : {
            labels: chartData.labels,
            datasets : [{label : indices[title],
              data: chartData.indexValues, 
              borderWidth: 1.5,
              borderColor: 'rgba(0, 131, 26, 0.5)',
              backgroundColor: 'rgba(0, 131, 26, 0.5)',
              yAxisID: 'y'},
              {label : 'Dark Pool Value', 
              data: chartData.dixValues, 
              borderWidth: 1.5,
              borderColor: 'rgba(0, 68, 255, 0.9)',
              backgroundColor: 'rgba(0, 68, 255, 0.9)',
              yAxisID: 'y1'}]
          }}]}, () => {});
        }
      }).catch(() => {
          return;
      });
    }

    render() {
      if (!this.props.currentUser.isAuthenticated) {
        return (<Redirect to={{ pathname: '/login' }} />);
      }
      if (this.props.currentUser.currentSubscriptionEndDate === TIER1) {
        return (<Redirect to={{ pathname: '/dashboard' }} />);
      }
      if (window.innerWidth <= 600) {
        options.maintainAspectRatio = false;
      } else {
        options.maintainAspectRatio = true;
      }
      let lines = [];
      for (let i = 0; i < this.state.data.length; i+=1) {
        lines.push(<div className='row'>
            <Line options={options} plugins={[plugin]} data={this.state.data[i].data} /></div>);
      }
    
      return (
          <div>
            {Object.keys(this.state.data).length !== 0 ? (
              <div class='multi-line-chart-wrapper'>
                {lines}
              </div>
            ) : (<div></div>)}
          </div>
      );
    }
}

export default DarkPool;