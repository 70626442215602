import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            email: '',
            password: '',
            passwordConfirmation: ''
        };
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSubmit = e => {
        e.preventDefault();
        const authType = 'register';
        this.props.onAuth(authType, this.state)
            .then(() => {
                this.setState({sent: 'Account Created! Please Login.'}, () => {
                    return;
                });
            })
            .catch((error) => {
                this.setState({sent: null}, () => {
                    this.setState({errors: error}, () => {
                        return;
                    });
                });
            });
    }

    render() {
        if (this.props.currentUser.isAuthenticated) {
            return (<Redirect to={{ pathname: '/login' }} />);
        }
        const { errors, history, removeError } = this.props;
        history.listen(() => {
            removeError();
        });

        return (
            <div className="register_container">
                <div className="image">
                    <h1>Welcome To Quantopia</h1>
                </div>
                <div className="content">
                    <form onSubmit={this.handleSubmit}>
                        <h1>Register (ALL SPOTS FILLED! Spots may re-open sometime in the future.)</h1>
                        {/* {errors.message && (
                            <div className='alert alert-danger'>{errors.message}</div>
                        )}
                        {this.state.sent && (
                            <div className='alert alert-success'>{this.state.sent}</div>
                        )}
                        <div className="form-group">
                            <label htmlFor="username">Username</label>
                            <br></br>
                            <input
                                autoComplete="off"
                                className="form-control"
                                id="username"
                                name="username"
                                type="text"
                                required
                                onChange={this.handleChange}
                            ></input>
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">E-mail</label>
                            <br></br>
                            <input
                                autoComplete="off"
                                className="form-control"
                                id="email"
                                name="email"
                                type="email"
                                required
                                onChange={this.handleChange}
                            ></input>
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <br></br>
                            <input
                                autoComplete="off"
                                className="form-control"
                                id="password"
                                name="password"
                                type="password"
                                required
                                onChange={this.handleChange}
                            ></input>
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password Confirmation</label>
                            <br></br>
                            <input
                                autoComplete="off"
                                className="form-control"
                                id="passwordConfirmation"
                                name="passwordConfirmation"
                                type="password"
                                required
                                onChange={this.handleChange}
                            ></input>
                        </div>
                        <hr></hr>
                        <Link className="fp" to="/login">Already Have An Account?</Link>
                        <hr></hr>
                        <div className="form-group">
                            <button
                                type="submit"
                                className="btn">
                                Register
                            </button>
                        </div> */}
                    </form>
                </div>
            </div>
        );
    }
}

export default Register;