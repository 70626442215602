import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../../store/actions/auth';

class AccountSettings extends Component {
  
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleEmail = e => {
        e.preventDefault();
        const authType = 'updatemail';
        this.props.updateUser(authType, this.state)
            .then(() => {
                this.setState({sent: 'Email Updated!'}, () => {
                    return;
                });
            })
            .catch((error) => {
                this.setState({sent: null}, () => {
                    this.setState({errors: error}, () => {
                        return;
                    });
                });
            });
    }

    handlePassword = e => {
        e.preventDefault();
        const authType = 'updatepassword';
        this.props.updateUser(authType, this.state)
            .then(() => {
                this.setState({sent: 'Password Updated!'}, () => {
                    return;
                });
            })
            .catch((error) => {
                this.setState({sent: null}, () => {
                    this.setState({errors: error}, () => {
                        return;
                    });
                });
            });
    }

    handleDelete = e => {
        e.preventDefault();
        const authType = 'deleteaccount';
        this.props.deleteUser(authType, this.state)
            .then(() => {
                this.props.logout();
                this.props.history.push('/');
            })
            .catch(error => {
                this.setState({sent: null}, () => {
                    this.setState({errors: error}, () => {
                        return;
                    });
                });
            });
    }

    render() {
        if (!this.props.currentUser.isAuthenticated) {
            return (<Redirect to={{ pathname: '/login' }} />);
        }
        const { errors, history, removeError } = this.props;
        history.listen(() => {
            removeError();
        });
        
        return (
            <div id='accountsettings' class='single-bar-chart-wrapper'>
                <div className="account_settings_container">
                    <div className="content">
                    <h1>Account Settings</h1>
                        {/* {errors.message && (
                            <div className='alert alert-danger'>{this.state.errors}</div>
                        )} */}
                        {this.state.sent && (
                            <div className='alert alert-success'>{this.state.sent}</div>
                        )}
                        <form>
                            <div className="form-group">
                                <label htmlFor="username">Username</label>
                                <br></br>
                                <input
                                    autoComplete="off"
                                    className="form-control"
                                    type="text"
                                    value={this.props.currentUser.username}
                                    readonly
                                    disabled
                                ></input>
                            </div>
                        </form>

                        <form onSubmit={this.handleEmail}>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <br></br>
                                <input
                                    autoComplete="off"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    type="email"
                                    placeholder={this.props.currentUser.email}
                                    onChange={this.handleChange}
                                ></input>
                            </div>
                            <div className="form-group">
                                <button
                                    type="submit"
                                    className="btn">
                                    Update Email
                                </button>
                            </div>
                        </form>

                        <form onSubmit={this.handlePassword}>
                            <div className="form-group">
                                <label htmlFor="password">Current Password</label>
                                <br></br>
                                <input
                                    autoComplete="off"
                                    className="form-control"
                                    id="currentPassword"
                                    name="currentPassword"
                                    type="password"
                                    required
                                    onChange={this.handleChange}
                                ></input>
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <br></br>
                                <input
                                    autoComplete="off"
                                    className="form-control"
                                    id="password"
                                    name="password"
                                    type="password"
                                    required
                                    onChange={this.handleChange}
                                ></input>
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Password Confirmation</label>
                                <br></br>
                                <input
                                    autoComplete="off"
                                    className="form-control"
                                    id="passwordConfirmation"
                                    name="passwordConfirmation"
                                    type="password"
                                    required
                                    onChange={this.handleChange}
                                ></input>
                            </div>
                            <div className="form-group">
                                <button
                                    type="submit"
                                    className="btn">
                                    Update Password
                                </button>
                            </div>
                        </form>

                        {/* <form onSubmit={this.handleDiscordID}>
                        <div className="form-group">
                                <label htmlFor="Discord ID">Discord ID</label>
                                <br></br>
                                <input
                                    autoComplete="off"
                                    className="form-control"
                                    id="discordId"
                                    name="discordId"
                                    type="text"
                                    placeholder={this.props.currentUser.discordID}
                                    onChange={this.handleChange}
                                    required
                                ></input>
                            </div>
                            <div className="form-group">
                                <button
                                    type="submit"
                                    className="btn">
                                    Update Discord ID
                                </button>
                            </div>
                        </form> */}

                        <form onSubmit={this.handleDelete}>
                            <div className="form-group">
                                <label htmlFor="Delete Account">Delete Account</label>
                                <br></br>
                                <input
                                    autoComplete="off"
                                    className="form-control"
                                    id="password"
                                    name="password"
                                    type="password"
                                    onChange={this.handleChange}
                                    placeholder="Enter Password to Delete Account"
                                    required
                                ></input>
                            </div>
                            <div className="form-group">
                                <button
                                    id="deleteaccount"
                                    type="submit"
                                    className="btn">
                                    Delete Account
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        currentUser : state.currentUser
    };
}

export default connect(mapStateToProps, { logout })(AccountSettings);