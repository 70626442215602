import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

ChartJS.defaults.color = "#ffffff";
ChartJS.defaults.plugins.legend.onHover = function() {
  document.body.style.cursor = 'pointer';
};
ChartJS.defaults.plugins.legend.onLeave = function() {
  document.body.style.cursor = 'unset';
};

const plugin = {
  id: 'customCanvasBackgroundColor',
  beforeDraw: (chart, args, options) => {
    const {ctx} = chart;
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = options.color || '#0';
    ctx.fillRect(10, 0, chart.width, chart.height);
    ctx.restore();
  }
};

const options = {
  responsive: true,
  elements: {
    point: {
      radius: 0.5
    }
  },
  layout: {
    padding: 50
  },
  scales: {
    x: {
      ticks: {
        maxTicksLimit: 9,
      }
    },
    y: {
      type: 'linear',
      display: true,
      position: 'left',
    },
    y1: {
      type: 'linear',
      display: true,
      position: 'right',
      grid: {
        drawOnChartArea: false,
      },
    },
  },
  plugins: {
    legend: {
      position: 'top',
    },
    tooltip: {
      mode: 'nearest',
      intersect: false
    },
    title: {
      display: true,
      text: 'Single Stock Momentum',
      color: 'white',
      font: {
        size: 25,
        family: 'Quicksand',
        lineHeight: 1
      },
    },
    zoom: {
      zoom: {
        wheel: {
          enabled: true,
        },
        pinch: {
          enabled: true
        },
        mode: 'x',
      },
      pan: {
        enabled: true,
        mode: 'x',
      },
    }
  },
};

const CHARTPADDING = 50;
const TIER1 = '';

class SingleStockMomentumTop extends Component {
 
    constructor(props) {
        super(props);
        this.state = {
          data : []
        }
    }

    componentDidMount() {
      if (!this.props.currentUser.isAuthenticated || this.props.currentUser.currentSubscriptionEndDate === TIER1) {
        return;
      }
      this.props.apiCall('get', '/singlestockmomentumtop', this.state)
      .then((data) => {
        for (const [key, value] of Object.entries(data)) {
          let chartData = {labels : [], indexValues: [], values14 : [], values42 : [], values63 : []};
          let results14 = value['shortterm'].split(',');
          let results42 = value['mediumterm'].split(',');
          let results63 = value['longterm'].split(',');
          let indexValues = value['indexValue'].split(',');
          results14.pop();
          for (let i = 0; i < results14.length; i++) {
            let dateValuePair14 = results14[i].split(':');
            let dateValuePair42 = results42[i].split(':');
            let dateValuePair63 = results63[i].split(':');
            let indexValuePair = indexValues[i].split(':');
            if (results14[1] === 'nan') {
              continue;
            }
            chartData.labels.push(dateValuePair14[0]);
            chartData.values14.push(parseFloat(dateValuePair14[1]));
            chartData.values42.push(parseFloat(dateValuePair42[1]));
            chartData.values63.push(parseFloat(dateValuePair63[1]));
            chartData.indexValues.push(parseFloat(indexValuePair[1]));
          }
          for (let i = 0; i < CHARTPADDING; i++) {
            chartData.labels.push(' ');
          }
          let title = value.index; // Use the stock symbol directly
          try {
            this.setState({
              data: [
                ...this.state.data,
                {
                  data: {
                    labels: chartData.labels,
                    datasets: [
                      {
                        label: title,
                        data: chartData.indexValues,
                        borderWidth: 1.5,
                        borderColor: 'rgba(0, 131, 26, 0.5)',
                        backgroundColor: 'rgba(0, 131, 26, 0.5)',
                        yAxisID: 'y',
                        hidden: true
                      },
                      {
                        label: ['Mom 14'],
                        data: chartData.values14,
                        borderWidth: 1.5,
                        borderColor: 'rgba(231, 76, 60, 1)',
                        backgroundColor: 'rgba(231, 76, 60, 0.5)',
                        yAxisID: 'y1',
                        hidden: false
                      },
                      {
                        label: ['Mom 42'],
                        data: chartData.values42,
                        borderWidth: 1.5,
                        borderColor: 'rgba(255, 215, 0, 1)',
                        backgroundColor: 'rgba(255, 215, 0, 0.5)',
                        yAxisID: 'y1',
                        hidden: false
                      },
                      {
                        label: ['Mom 63'],
                        data: chartData.values63,
                        borderWidth: 1.5,
                        borderColor: 'rgba(16, 68, 223, 0.85)',
                        backgroundColor: 'rgba(21, 0, 255, 0.5)',
                        yAxisID: 'y1'
                      }
                    ]
                  }
                }
              ]
            }, () => {});
          } catch (error) {
            console.error('Error updating state:', error);
            // Continue the outer loop (if you have one)
          }
        }
      }).catch((err) => {
          return;
      });
    }

    render() {
      if (!this.props.currentUser.isAuthenticated) {
        return (<Redirect to={{ pathname: '/login' }} />);
      }
      if (this.props.currentUser.currentSubscriptionEndDate === TIER1) {
        return (<Redirect to={{ pathname: '/dashboard' }} />);
      }
      if (window.innerWidth <= 600) {
        options.maintainAspectRatio = false;
      } else {
        options.maintainAspectRatio = true;
      }
      let lines = []
      for (let i = 0; i < this.state.data.length; i+=1) {
          lines.push(<div className='row' key={i}>
              <Line options={options} plugins={[plugin]} data={this.state.data[i].data} />
          </div>);
      }
     
      return (
          <div>
            {Object.keys(this.state.data).length !== 0 ? (
              <div className='multi-line-chart-wrapper'>
                {lines}
              </div>
            ) : (<div>Loading...</div>)}
          </div>
      );
    }
}

export default SingleStockMomentumTop;
