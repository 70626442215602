import { SET_CURRENT_USER } from '../actionTypes';
import jwtDecode from 'jwt-decode';

const DEFAULT_STATE = {
    isAuthenticated: false,
    user: {}
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case SET_CURRENT_USER: 
            if (typeof(action.user) === 'string') {
                let user = jwtDecode(action.user);
                return {
                    isAuthenticated: Object.keys(user.sub).length,
                    username: user.sub.username,
                    email: user.sub.email,
                    discordID: user.sub.discordID,
                    subscriptionHistory: user.sub.subscriptionHistory,
                    currentSubscriptionEndDate: user.sub.currentSubscriptionEndDate,
                    isRecurring: user.sub.isRecurring
                };
            } else {
                return {
                    isAuthenticated: false,
                    username: '',
                    email: '',
                    discordID: '',
                    subscriptionHistory: '',
                    currentSubscriptionEndDate: '',
                    isRecurring: ''
                };
            }
        default:
            return state;
    }
}