import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../store/actions/auth';
import Logo from '../images/icon128.png'
import Blank from '../images/blank.png'
import Pdf from '../files/Models Cheatsheet.pdf';

const TIER1 = ''

class HeaderSidebar extends Component {
    constructor(props) {
        super(props);
    }

    logout = e => {
        e.preventDefault();
        this.props.logout();
        this.props.history.push('/');
    }

    render() {
        return (
            <div>
                <header>
                    <h3>
                        <Link to="/">
                            <img src={Logo} alt='Quantopia Home'>
                            </img>Quantopia
                        </Link>
                    </h3>
                    {this.props.currentUser.isAuthenticated && this.props.currentUser.currentSubscriptionEndDate !== TIER1 ? (
                        <div>
                            <h2><a href = {Pdf} target = "_blank"><i class="fas fa-question"></i> Cheatsheet</a></h2>
                        </div>
                    ) : (
                        <div>
                            <h2>
                                <a href="/#how-it-works"><i class="fas fa-question"></i> How It Works</a>
                            </h2>
                        </div>
                    )}
                    {/* <h2><a href="https://discord.gg/VdcgSRES" target="_blank">
                        <i class="fab fa-discord"></i> Join The Discord
                        </a>
                    </h2> */}
                    <h2><Link to='/dashboard' >
                        <i class="fas fa-columns"></i> Dashboard
                        </Link>
                    </h2>
                    {this.props.currentUser.isAuthenticated ? (
                        <div>
                            <nav>
                                <img src={Blank}></img>
                                <li className="dropdown"><p className="dropdown-text">
                                    {`${this.props.currentUser.username} `}
                                    <i className="fas fa-caret-down"></i></p>
                                    <div className="dropdown-content">
                                        <a href='/account-settings'><i class="far fa-user"></i> Account Settings</a>
                                        <a href='/membership-settings'><i class="fa fa-tag"></i> Your Membership</a>
                                        <a onClick={this.logout}><i class="fas fa-power-off"></i> Log Out</a>
                                    </div>
                                </li>
                            </nav>
                        </div>
                    ) : (
                        <h2><Link to="/login">
                            <i class="fas fa-user"></i> Login/Register
                            </Link>
                        </h2>
                    )}
                </header>
                <nav class="sidebar">
                    <div class="avatar">
                        <img src={Blank}></img>
                        <h2></h2>
                    </div>
                    <ul>
                        <br></br>
                        {this.props.currentUser.currentSubscriptionEndDate !== TIER1 ? (
                            <li><span><a href="/entropy"><i class="fas fa-dragon"  style={{color: "#000000"}}></i> - Market Entropy</a></span></li>
                        ) : (
                            <li><span><a href="/membership-settings"><i class="fas fa-project-diagram"  style={{color: "#000000"}}></i> - Model Hidden (Subscribe to View)</a></span></li>
                        )}
                        <hr></hr>
                        {this.props.currentUser.currentSubscriptionEndDate !== TIER1 ? (
                            <li><span><a href="/networks"><i class="fas fa-project-diagram"  style={{color: "#000000"}}></i> - Market Correlation</a></span></li>
                        ) : (
                            <li><span><a href="/membership-settings"><i class="fas fa-project-diagram"  style={{color: "#000000"}}></i> - Model Hidden (Subscribe to View)</a></span></li>
                        )}
                        {/* <hr></hr>
                        {this.props.currentUser.currentSubscriptionEndDate !== TIER1 ? (
                            <li><span><a href="/momentumoriginal"><i class="fas fa-chart-line" style={{color: "#000000"}}></i> - Market Momentum</a></span></li>
                        ) : (
                            <li><span><a href="/membership-settings"><i class="fas fa-chart-line"  style={{color: "#000000"}}></i> - Model Hidden (Subscribe to View)</a></span></li>
                        )} */}
                        <hr></hr>
                        {this.props.currentUser.currentSubscriptionEndDate !== TIER1 ? (
                            <li><span><a href="/momentum"><i class="fas fa-chart-line" style={{color: "#000000"}}></i> - Market Momentum Projected</a></span></li>
                        ) : (
                            <li><span><a href="/membership-settings"><i class="fas fa-chart-line"  style={{color: "#000000"}}></i> - Model Hidden (Subscribe to View)</a></span></li>
                        )}
                        <hr></hr>
                        {this.props.currentUser.currentSubscriptionEndDate !== TIER1 ? (
                            <li><span><a href="/gammaexposure"><i class="fas fa-sun" style={{color: "#000000"}}></i> - Market Gamma Exposure Indicator</a></span></li>
                        ) : (
                            <li><span><a href="/membership-settings"><i class="fas fa-sun"  style={{color: "#000000"}}></i> - Model Hidden (Subscribe to View)</a></span></li>
                        )}
                        <hr></hr>
                        {this.props.currentUser.currentSubscriptionEndDate !== TIER1 ? (
                            <li><span><a href="/darkpool"><i class="fas fa-sun" style={{color: "#000000"}}></i> - Market Dark Pool Indicator</a></span></li>
                        ) : (
                            <li><span><a href="/membership-settings"><i class="fas fa-sun"  style={{color: "#000000"}}></i> - Model Hidden (Subscribe to View)</a></span></li>
                        )}
                        <hr></hr>
                        {this.props.currentUser.currentSubscriptionEndDate !== TIER1 ? (
                            <li><span><a href="/dashboard"><i class="fas fa-wave-square"  style={{color: "#000000"}}></i> - Market Monthly Seasonality</a></span></li>
                        ) : (
                            <li><span><a href="/membership-settings"><i class="fas fa-wave-square"  style={{color: "#000000"}}></i> - Model Hidden (Subscribe to View)</a></span></li>
                        )}
                        <hr></hr>
                        {this.props.currentUser.currentSubscriptionEndDate !== TIER1 ? (
                            <li><span><a href="/quarterlyseasonality"><i class="fas fa-wave-square" style={{color: "#000000"}}></i> - Market Quarterly Seasonality</a></span></li>
                        ) : (
                            <li><span><a href="/membership-settings"><i class="fas fa-wave-square"  style={{color: "#000000"}}></i> - Model Hidden (Subscribe to View)</a></span></li>
                        )}
                        <hr></hr>
                        {/* {this.props.currentUser.currentSubscriptionEndDate !== TIER1 ? (
                            <li><span><a href="/singlestockmomentumoriginal"><i class="fas fa-chart-line" style={{color: "#000000"}}></i> - Single Stock Momentum</a></span></li>
                        ) : (
                            <li><span><a href="/membership-settings"><i class="fas fa-chart-line"  style={{color: "#000000"}}></i> - Model Hidden (Subscribe to View)</a></span></li>
                        )}
                        <hr></hr> */}
                        {this.props.currentUser.currentSubscriptionEndDate !== TIER1 ? (
                            <li><span><a href="/singlestockmomentum"><i class="fas fa-chart-line" style={{color: "#000000"}}></i> - Single Stock Momentum Projected</a></span></li>
                        ) : (
                            <li><span><a href="/membership-settings"><i class="fas fa-chart-line"  style={{color: "#000000"}}></i> - Model Hidden (Subscribe to View)</a></span></li>
                        )}
                        <hr></hr>
                        {this.props.currentUser.currentSubscriptionEndDate !== TIER1 ? (
                            <li><span><a href="/singlestockmomentumtop"><i class="fas fa-chart-line" style={{color: "#000000"}}></i> - Single Stock Momentum Top</a></span></li>
                        ) : (
                            <li><span><a href="/membership-settings"><i class="fas fa-chart-line"  style={{color: "#000000"}}></i> - Model Hidden (Subscribe to View)</a></span></li>
                        )}
                        <hr></hr>
                        {this.props.currentUser.currentSubscriptionEndDate !== TIER1 ? (
                            <li><span><a href="/cryptocorrelation"><i class="fas fa-chart-line" style={{color: "#000000"}}></i> - Crypto Correlation</a></span></li>
                        ) : (
                            <li><span><a href="/membership-settings"><i class="fas fa-wave-square"  style={{color: "#000000"}}></i> - Model Hidden (Subscribe to View)</a></span></li>
                        )}
                        {/* <hr></hr>
                        {this.props.currentUser.currentSubscriptionEndDate !== TIER1 ? (
                            <li><span><a href="/cryptomomentumoriginal"><i class="fas fa-chart-line" style={{color: "#000000"}}></i> - Single Crypto Momentum</a></span></li>
                        ) : (
                            <li><spa    n><a href="/membership-settings"><i class="fas fa-wave-square"  style={{color: "#000000"}}></i> - Model Hidden (Subscribe to View)</a></span></li>
                        )} */}
                        <hr></hr>
                        {this.props.currentUser.currentSubscriptionEndDate !== TIER1 ? (
                            <li><span><a href="/cryptomomentum"><i class="fas fa-chart-line" style={{color: "#000000"}}></i> - Crypto Momentum Projected</a></span></li>
                        ) : (
                            <li><span><a href="/membership-settings"><i class="fas fa-wave-square"  style={{color: "#000000"}}></i> - Model Hidden (Subscribe to View)</a></span></li>
                        )}
                        <hr></hr>
                        {this.props.currentUser.currentSubscriptionEndDate !== TIER1 ? (
                            <li><span><a href="/cryptotiming"><i class="fas fa-wave-square" style={{color: "#000000"}}></i> - Crypto Seasonality</a></span></li>
                        ) : (
                            <li><span><a href="/membership-settings"><i class="fas fa-wave-square"  style={{color: "#000000"}}></i> - Model Hidden (Subscribe to View)</a></span></li>
                        )}
                        <hr></hr>
                        {this.props.currentUser.currentSubscriptionEndDate !== TIER1 ? (
                            <li><span><a href="/cryptomomentumtop"><i class="fas fa-chart-line" style={{color: "#000000"}}></i> - Single Crypto Momentum Top</a></span></li>
                        ) : (
                            <li><span><a href="/membership-settings"><i class="fas fa-wave-square"  style={{color: "#000000"}}></i> - Model Hidden (Subscribe to View)</a></span></li>
                        )}
                        <hr></hr>
                    </ul>
                </nav>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        currentUser : state.currentUser
    };
}

export default connect(mapStateToProps, { logout })(HeaderSidebar);