import { apiCall, setTokenHeader } from '../../services/api';
import { SET_CURRENT_USER } from '../actionTypes';
import { addError, removeError } from './errors';

const updateTokenRoutes = new Set(['login', 'refreshtoken', 'updatepassword', 'updatemail', 'updatediscord', 'subscribe', 'cancelsubscription']);

export function setCurrentUser(user) {
    return {
        type : SET_CURRENT_USER,
        user
    };
}

export function setAuthorizationToken(token) {
    setTokenHeader(token);
}

export function logout() {
    return dispatch => {
        localStorage.clear();
        setAuthorizationToken(false);
        dispatch(setCurrentUser({}));
    }
}

export function getUser(type, userData) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            return apiCall('get', `/auth/${type}`, userData)
                .then(({token}) => {
                    if (updateTokenRoutes.has(type)) {
                        localStorage.setItem('jwtToken', token);
                        setAuthorizationToken(token);
                        dispatch(setCurrentUser(token));
                    }
                    dispatch(removeError());
                    resolve();
                })
                .catch(err => {
                    dispatch(addError(err));
                    reject(err);
                });
        });
    }
}

export function authUser(type, userData) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            return apiCall('post', `/auth/${type}`, userData)
                .then(({token}) => {
                    if (updateTokenRoutes.has(type)) {
                        localStorage.setItem('jwtToken', token);
                        setAuthorizationToken(token);
                        dispatch(setCurrentUser(token));
                    }
                    dispatch(removeError());
                    resolve();
                })
                .catch(err => {
                    dispatch(addError(err));
                    reject(err);
                });
        });
    }
}

export function updateUser(type, userData) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            return apiCall('put', `/auth/${type}`, userData)
                .then(({token}) => {
                    if (updateTokenRoutes.has(type)) {
                        localStorage.setItem('jwtToken', token);
                        setAuthorizationToken(token);
                        dispatch(setCurrentUser(token));
                    }
                    dispatch(removeError());
                    resolve();
                })
                .catch(err => {
                    dispatch(addError(err));
                    reject(err);
                });
        });
    }
}

export function deleteUser(type, userData) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            return apiCall('delete', `/auth/${type}`, userData)
                .then(() => {
                    dispatch(removeError());
                    resolve();
                })
                .catch(err => {
                    dispatch(addError(err));
                    reject(err);
                });
        });
    }
}