import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { logout } from '../store/actions/auth';
import Logo from '../images/icon128.png';
import Blank from '../images/blank.png';
import Pdf from '../files/Models Cheatsheet.pdf';

const TIER1 = '';

class Header extends Component {
    constructor(props) {
        super(props);
    }

    logout = e => {
        e.preventDefault();
        this.props.logout();
    }

    render() {
        return (
            <div>
                <header>
                    <h3><a href="/">
                            <img src={Logo} alt='Quantopia Home'>
                            </img>Quantopia
                        </a>
                    </h3>
                    {this.props.currentUser.isAuthenticated && this.props.currentUser.currentSubscriptionEndDate !== TIER1 ? (
                        <div></div>
                        // <div>
                        //     <h2><a href = {Pdf} target = "_blank"><i class="fas fa-question"></i> Cheatsheet</a></h2>
                        // </div>
                    ) : (
                        <div>
                            <h2>
                                <a href="/#how-it-works"><i class="fas fa-question"></i> How It Works</a>
                            </h2>
                        </div>
                    )}
                    {/* <h2><a href="/#pricing">
                        <i class="fas fa-dollar-sign"></i> Pricing
                        </a>
                    </h2> */}
                    {/* <h2><Link to={{pathname: "https://discord.gg/VdcgSRES"}} target="_blank" >
                        <i class="fab fa-discord"></i> Join The Discord
                        </Link>
                    </h2> */}
                    <h2><Link to='/dashboard' >
                        <i class="fas fa-columns"></i> Dashboard
                        </Link>
                    </h2>
                    {this.props.currentUser.isAuthenticated ? (
                        <div>
                            <nav>
                                <img src={Blank}></img>
                                <li className="dropdown"><p className="dropdown-text">
                                    {`${this.props.currentUser.username} `}
                                    <i className="fas fa-caret-down"></i></p>
                                    <div className="dropdown-content">
                                        <a href='/account-settings'><i class="far fa-user"></i> Account Settings</a>
                                        <a href='/membership-settings'><i class="fa fa-tag"></i> Your Membership</a>
                                        <a onClick={this.logout}><i class="fas fa-power-off"></i> Log Out</a>
                                    </div>
                                </li>
                            </nav>
                        </div>
                    ) : (
                        <h2><Link to="/login">
                            <i class="fas fa-user"></i> Login/Register
                            </Link>
                        </h2>
                    )}
                </header>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        currentUser : state.currentUser
    };
}

export default connect(mapStateToProps, { logout })(Header);