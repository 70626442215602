import axios from 'axios';

if (process.env.NODE_ENV === 'development') {
    // dev code
    axios.defaults.baseURL = 'http://localhost:8000/api/v1';
} else {
    // production code
    axios.defaults.baseURL = 'https://quantopia.ai/api/v1';
}

export function setTokenHeader(token) {
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete axios.defaults.headers.common['Authorization'];
    }
}

export function apiCall(method, path, data) {
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        for ( var key in data ) {
            formData.append(key, data[key]);
        }
        return axios({
                method: method.toLowerCase(),
                url: path,
                data: formData,
                headers: { "Content-Type": "multipart/form-data" }
            }).then(res => {
                return resolve(res.data);
            })
            .catch((err) => {
                return reject(err.response);
            });
    });
}