import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

ChartJS.defaults.color = "#ffffff";
ChartJS.defaults.plugins.legend.onHover = function() { 
  document.body.style.cursor = 'pointer'; 
};
ChartJS.defaults.plugins.legend.onLeave = function() { 
  document.body.style.cursor = 'unset'; 
};

const indices = {
  'timing': 'US Index ',
  'timing_crypto': 'Crypto Index '
}

const plugin = {
  id: 'customCanvasBackgroundColor',
  beforeDraw: (chart, args, options) => {
    const {ctx} = chart;
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = options.color || '#0';
    ctx.fillRect(5, 5, chart.width, chart.height);
    ctx.restore();
  }
};

const options = {
  responsive: true,
  layout: {
    padding: 25
  },
  plugins: {
    legend: {
      position: 'top',
    },
    tooltip: {
      mode: 'nearest',
      intersect: false
    },
    title: {
      display: true,
      text: 'Index vs Monthly Returns',
      color: 'white',
      font: {
        size: 25,
        family: 'Quicksand',
        lineHeight: 2.5,
      },
    },
    zoom: {
      zoom: {
        wheel: {
          enabled: true,
        },
        pinch: {
          enabled: true
        },
        mode: 'x',
      },
      pan: {
        enabled: true,
        mode: 'x',
      },
    }
  },
};

const TIER1 = '';

class MonthlySeasonality extends Component {

    constructor(props) {
        super(props);
        this.state = {
          data : []
        }
    }

    componentDidMount() {
      if (!this.props.currentUser.isAuthenticated || this.props.currentUser.currentSubscriptionEndDate === TIER1) {
        return;
      }
      this.props.apiCall('get', '/monthlyseasonality', this.state)
      .then((data) => {
          for (const [key, value] of Object.entries(data)) {
            let chartData = {labels : [], values : []}
            let results = value.value.split(',');
            results.pop();
            results.forEach(function(result) {
              let resultPair = result.split(':');
              chartData.labels.push(resultPair[0]);
              chartData.values.push(resultPair[1]);
            });
            this.setState({data: [...this.state.data, {data : {
              labels: chartData.labels,
              datasets : [{label : indices[value.index] + 'Average Returns (%)', 
                data: chartData.values,
                backgroundColor: 'rgba(21, 0, 255, 0.7)'}]
            }}]}, () => {});
          }
      }).catch(() => {
          return;
      });
    }

    render() {
      if (!this.props.currentUser.isAuthenticated) {
        return (<Redirect to={{ pathname: '/login' }} />);
      }
      if (window.innerWidth <= 600) {
        options.maintainAspectRatio = false;
      } else {
        options.maintainAspectRatio = true;
      }
      let bars = [];
      for (let i = 0; i < this.state.data.length; i+=1) {
          bars.push(<div className='row'>
              <Bar options={options} plugins={[plugin]} data={this.state.data[i].data} />
          </div>);
      }

      return (
        <div>
          {this.props.currentUser.currentSubscriptionEndDate === TIER1 ? (
            <div class='single-bar-chart-wrapper'>
                <h3 class="title">Membership Pricing</h3>
                <ul class="grid2">
                  <li>
                    <i class="fas fa-hand-holding-usd fa-2x"></i>
                    <hr></hr>
                    <ul>
                      <li><i class="fas fa-user-secret fa-2x"></i><p>Access to all 6 quantitative models.</p></li>
                      <li><i class="fas fa-server fa-2x"></i><p>Full member privileges in the Discord - Private trading group channels and market alpha.</p></li>
                      {/* <li><i class="fas fa-server fa-2x"></i><p>Full Member Privileges in the Discord - Receive signals, market updates from the team, exclusive research, and member channels.</p></li> */}
                      {/* <li><i class="fas fa-book fa-2x"></i><p>Extended course available in the Discord.</p></li> */}
                      <li><i class="fas fa-lock fa-2x"></i><p>Limited Spots Available - This is done in order to preserve the alpha of the strategy. You may gain a spot if a member relinquishes their subscription.</p></li>
                    </ul>
                    <a id='single-bar-button' href="/membership-settings" class="gradientbtn">Become a Member - $250/MONTH</a>
                  </li>
                </ul>
            </div>
          ) : (
            <div>
              {Object.keys(this.state.data).length !== 0 ? (
                <div class='multi-line-chart-wrapper'>
                  {bars}
                </div>
              ) : (<div></div>)}
            </div>
          )}
        </div>
      );
    }
}

export default MonthlySeasonality;