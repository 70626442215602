import rootReducer from './reducers';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

// actions (does the work) -> reducers (set the state) -> rootreducer combine reducers -> store -> components
export function configureStore() {
    const store = createStore(
        rootReducer,
        compose (
          applyMiddleware(thunk),
          window.devToolsExtension ? window.devToolsExtension() : f => f
        )
    );
    return store;
}