import React from 'react';
import { Switch, Route, withRouter }  from 'react-router-dom';
import { connect } from 'react-redux';
import { apiCall } from '../services/api';
import { getUser, authUser, updateUser, deleteUser } from '../store/actions/auth';
import { removeError } from '../store/actions/errors';
import Header from './Header';
import Footer from './Footer';
import HeaderSidebar from './HeaderSidebar';
import Index from '../components/Index';
import PrivacyPolicy from '../components/PrivacyPolicy';
import TermsAndConditions from '../components/TermsAndConditions';
import Login from '../components/auth/Login';
import Register from '../components/auth/Register';
import ResetPassword from '../components/auth/ResetPassword';
import AccountSettings from '../components/auth/AccountSettings';
import MembershipSettings from '../components/auth/MembershipSettings';
import MonthlySeasonality from '../components/MonthlySeasonality';
import QuarterlySeasonality from '../components/QuarterlySeasonality';
import DarkPool from '../components/DarkPool';
import GammaExposure from '../components/GammaExposure';
import Momentum from '../components/Momentum';
import MomentumOriginal from '../components/MomentumOriginal';
import SingleStockMomentum from '../components/SingleStockMomentum';
import SingleStockMomentumTop from '../components/SingleStockMomentumTop';
import SingleStockMomentumOriginal from '../components/SingleStockMomentumOriginal';
import Correlation from '../components/Correlation';
import Entropy from '../components/Entropy';
import CryptoTiming from '../components/CryptoTiming';
import CryptoCorrelation from '../components/CryptoCorrelation';
import CryptoMomentum from '../components/CryptoMomentum';
import CryptoMomentumTop from '../components/CryptoMomentumTop';
import CryptoMomentumOriginal from '../components/CryptoMomentumOriginal';

const Main = props => {
    const { errors, removeError, getUser, authUser, updateUser, deleteUser, currentUser } = props;
    return (
        <Switch>
            <Route exact path='/' render={props => {
                return (
                    <div>
                        <Header 
                            currentUser={currentUser} 
                            {...props}></Header>
                        <Index></Index>
                        <Footer></Footer>
                    </div>
                );
            }}>
            </Route>
            <Route exact path='/privacy-policy/' render={props => {
                return (
                    <div>
                        <Header 
                            currentUser={currentUser} 
                            {...props}></Header>
                        <PrivacyPolicy></PrivacyPolicy>
                        <Footer></Footer>
                    </div>
                );
            }}>
            </Route>
            <Route exact path='/terms-of-service/' render={props => {
                return (
                    <div>
                        <Header 
                            currentUser={currentUser} 
                            {...props}></Header>
                        <TermsAndConditions></TermsAndConditions>
                        <Footer></Footer>
                    </div>
                );
            }}>
            </Route>
            <Route exact path='/login/' render={props => {
                return (
                    <div>
                        <Header 
                            currentUser={currentUser} 
                            {...props}></Header>
                        <Login
                            removeError={removeError}
                            errors={errors}
                            onAuth={authUser}
                            currentUser={currentUser}
                            {...props}></Login>
                        <Footer></Footer>
                    </div>
                );
            }}></Route>
            {/* <Route exact path='/register/' render={props => {
                return (
                    <div>
                        <Header 
                            currentUser={currentUser} 
                            {...props}></Header>
                        <Register
                            removeError={removeError}
                            errors={errors}
                            onAuth={authUser}
                            currentUser={currentUser}
                            {...props}></Register>
                        <Footer></Footer>
                    </div>
                );
            }}></Route> */}
            <Route exact path='/reset-password/' render={props => {
                return (
                    <div>
                        <Header 
                            currentUser={currentUser} 
                            {...props}></Header>
                        <ResetPassword
                            removeError={removeError}
                            errors={errors}
                            onAuth={authUser}
                            currentUser={currentUser}
                            {...props}></ResetPassword>
                        <Footer></Footer>
                    </div>
                );
            }}></Route>
            <Route exact path='/account-settings/' render={props => {
                return (
                    <div>
                        <HeaderSidebar 
                            onAuth={authUser}
                            currentUser={currentUser}
                            {...props}></HeaderSidebar>
                        <AccountSettings
                            removeError={removeError}
                            errors={errors}
                            onAuth={authUser}
                            updateUser={updateUser}
                            deleteUser={deleteUser}
                            currentUser={currentUser}
                            {...props}></AccountSettings>
                        <Footer></Footer>
                    </div>
                );
            }}></Route>
            <Route exact path='/membership-settings/' render={props => {
                return (
                    <div>
                        <HeaderSidebar 
                            onAuth={authUser}
                            currentUser={currentUser}
                            {...props}></HeaderSidebar>
                        <MembershipSettings
                            removeError={removeError}
                            errors={errors}
                            apiCall={apiCall}
                            getUser={getUser}
                            onAuth={authUser}
                            updateUser={updateUser}
                            currentUser={currentUser}
                            {...props}></MembershipSettings>
                        <Footer></Footer>
                    </div>
                );
            }}></Route>
            <Route exact path='/dashboard/' render={props => {
                return (
                    <div>
                        <HeaderSidebar 
                            currentUser={currentUser} 
                            {...props}></HeaderSidebar>
                        <MonthlySeasonality 
                            apiCall={apiCall}
                            currentUser={currentUser}
                            {...props}></MonthlySeasonality>
                        <Footer></Footer>
                    </div>
                );
            }}>
            </Route>
            <Route exact path='/quarterlyseasonality/' render={props => {
                return (
                    <div>
                        <HeaderSidebar 
                            currentUser={currentUser} 
                            {...props}></HeaderSidebar>
                        <QuarterlySeasonality 
                            apiCall={apiCall}
                            currentUser={currentUser}
                            {...props}></QuarterlySeasonality>
                        <Footer></Footer>
                    </div>
                );
            }}>
            </Route>
            <Route exact path='/darkpool/' render={props => {
                return (
                    <div>
                        <HeaderSidebar 
                            currentUser={currentUser} 
                            {...props}></HeaderSidebar>
                        <DarkPool 
                            apiCall={apiCall}
                            currentUser={currentUser}
                            {...props}></DarkPool>
                        <Footer></Footer>
                    </div>
                );
            }}>
            </Route>
            <Route exact path='/gammaexposure/' render={props => {
                return (
                    <div>
                        <HeaderSidebar 
                            currentUser={currentUser} 
                            {...props}></HeaderSidebar>
                        <GammaExposure 
                            apiCall={apiCall}
                            currentUser={currentUser}
                            {...props}></GammaExposure>
                        <Footer></Footer>
                    </div>
                );
            }}>
            </Route>
            <Route exact path='/networks/' render={props => {
                return (
                    <div>
                        <HeaderSidebar 
                            currentUser={currentUser} 
                            {...props}></HeaderSidebar>
                        <Correlation 
                            apiCall={apiCall}
                            currentUser={currentUser}
                            {...props}></Correlation>
                        <Footer></Footer>
                    </div>
                );
            }}>
            </Route>
            <Route exact path='/entropy/' render={props => {
                return (
                    <div>
                        <HeaderSidebar 
                            currentUser={currentUser} 
                            {...props}></HeaderSidebar>
                        <Entropy 
                            apiCall={apiCall}
                            currentUser={currentUser}
                            {...props}></Entropy>
                        <Footer></Footer>
                    </div>
                );
            }}>
            </Route>
            <Route exact path='/momentum/' render={props => {
                return (
                    <div>
                        <HeaderSidebar 
                            currentUser={currentUser} 
                            {...props}></HeaderSidebar>
                        <Momentum 
                            apiCall={apiCall}
                            currentUser={currentUser}
                            {...props}></Momentum>
                        <Footer></Footer>
                    </div>
                );
            }}>
            </Route>
            <Route exact path='/momentumoriginal/' render={props => {
                return (
                    <div>
                        <HeaderSidebar 
                            currentUser={currentUser} 
                            {...props}></HeaderSidebar>
                        <MomentumOriginal
                            apiCall={apiCall}
                            currentUser={currentUser}
                            {...props}></MomentumOriginal>
                        <Footer></Footer>
                    </div>
                );
            }}>
            </Route>
            <Route exact path='/singlestockmomentum/' render={props => {
                return (
                    <div>
                        <HeaderSidebar 
                            currentUser={currentUser} 
                            {...props}></HeaderSidebar>
                        <SingleStockMomentum 
                            apiCall={apiCall}
                            currentUser={currentUser}
                            {...props}></SingleStockMomentum>
                        <Footer></Footer>
                    </div>
                );
            }}>
            </Route>
            <Route exact path='/singlestockmomentumtop/' render={props => {
                return (
                    <div>
                        <HeaderSidebar 
                            currentUser={currentUser} 
                            {...props}></HeaderSidebar>
                        <SingleStockMomentumTop
                            apiCall={apiCall}
                            currentUser={currentUser}
                            {...props}></SingleStockMomentumTop>
                        <Footer></Footer>
                    </div>
                );
            }}>
            </Route>
            <Route exact path='/singlestockmomentumoriginal/' render={props => {
                return (
                    <div>
                        <HeaderSidebar 
                            currentUser={currentUser} 
                            {...props}></HeaderSidebar>
                        <SingleStockMomentumOriginal 
                            apiCall={apiCall}
                            currentUser={currentUser}
                            {...props}></SingleStockMomentumOriginal>
                        <Footer></Footer>
                    </div>
                );
            }}>
            </Route>
            <Route exact path='/cryptocorrelation/' render={props => {
                return (
                    <div>
                        <HeaderSidebar 
                            currentUser={currentUser} 
                            {...props}></HeaderSidebar>
                        <CryptoCorrelation 
                            apiCall={apiCall}
                            currentUser={currentUser}
                            {...props}></CryptoCorrelation>
                        <Footer></Footer>
                    </div>
                );
            }}>
            </Route>
            <Route exact path='/cryptomomentum/' render={props => {
                return (
                    <div>
                        <HeaderSidebar 
                            currentUser={currentUser} 
                            {...props}></HeaderSidebar>
                        <CryptoMomentum 
                            apiCall={apiCall}
                            currentUser={currentUser}
                            {...props}></CryptoMomentum>
                        <Footer></Footer>
                    </div>
                );
            }}>
            </Route>
            <Route exact path='/cryptomomentumtop/' render={props => {
                return (
                    <div>
                        <HeaderSidebar 
                            currentUser={currentUser} 
                            {...props}></HeaderSidebar>
                        <CryptoMomentumTop 
                            apiCall={apiCall}
                            currentUser={currentUser}
                            {...props}></CryptoMomentumTop>
                        <Footer></Footer>
                    </div>
                );
            }}>
            </Route>
            <Route exact path='/cryptomomentumoriginal/' render={props => {
                return (
                    <div>
                        <HeaderSidebar 
                            currentUser={currentUser} 
                            {...props}></HeaderSidebar>
                        <CryptoMomentumOriginal 
                            apiCall={apiCall}
                            currentUser={currentUser}
                            {...props}></CryptoMomentumOriginal>
                        <Footer></Footer>
                    </div>
                );
            }}>
            </Route>
            <Route exact path='/cryptotiming/' render={props => {
                return (
                    <div>
                        <HeaderSidebar 
                            currentUser={currentUser} 
                            {...props}></HeaderSidebar>
                        <CryptoTiming 
                            apiCall={apiCall}
                            currentUser={currentUser}
                            {...props}></CryptoTiming>
                        <Footer></Footer>
                    </div>
                );
            }}>
            </Route>
            { /* <Route path='/users/:id/messages/new' component={withAuth(MessageForm)}></Route> */ }
        </Switch>
    );
};

function mapStateToProps(state) {
    return {
        currentUser : state.currentUser,
        errors : state.errors
    };
}

export default withRouter(connect(mapStateToProps, { getUser, authUser, updateUser, deleteUser, removeError })(Main));