import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

ChartJS.defaults.color = "#ffffff";
ChartJS.defaults.plugins.legend.onHover = function() { 
  document.body.style.cursor = 'pointer'; 
};
ChartJS.defaults.plugins.legend.onLeave = function() { 
  document.body.style.cursor = 'unset'; 
};

const indices = {
  'SP500': 'S&P 500',
  'NQ100': 'Nasdaq',
  'DOW30': 'Dow Jones Industrial Average',
  'crypto': 'Bitcoin'
}

const plugin = {
  id: 'customCanvasBackgroundColor',
  beforeDraw: (chart, args, options) => {
    const {ctx} = chart;
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = options.color || '#0';
    ctx.fillRect(10, 0, chart.width, chart.height);
    ctx.restore();
  }
};

const options = {
  responsive: true,
  // radius
  elements: {
    point: {
      radius: 0.5
    }
  },
  layout: {
    padding: 25
  },
  scales: {
    x: {
      ticks: {
        maxTicksLimit: 9
      }
    },
    y: {
      type: 'linear',
      display: true,
      position: 'left',
    },
    y1: {
      type: 'linear',
      display: true,
      position: 'right',
      grid: {
        drawOnChartArea: false,
      },
    },
  },
  plugins: {
    legend: {
      position: 'top',
    },
    tooltip: {
      mode: 'nearest',
      intersect: false
    },
    title: {
      display: true,
      text: 'Entropy vs Index Value',
      color: 'white',
      font: {
        size: 25,
        family: 'Quicksand',
        lineHeight: 1
      }
    },
    zoom: {
      zoom: {
        wheel: {
          enabled: true,
        },
        pinch: {
          enabled: true
        },
        mode: 'x',
      },
      pan: {
        enabled: true,
        mode: 'x',
      },
    },
  },
};

const CHARTPADDING = 50;
const TIER1 = '';

class Entropy extends Component {
  
    constructor(props) {
        super(props);
        this.state = {
          data : []
        }
    }

    componentDidMount() {
      if (!this.props.currentUser.isAuthenticated || this.props.currentUser.currentSubscriptionEndDate === TIER1) {
        return;
      }
      this.props.apiCall('get', '/entropy', this.state)
      .then((data) => {
        for (const [key, value] of Object.entries(data)) {
          let chartData = {labels : [], indexValues: [], v63 : [], v126 : [], v252 : []}
          let v63 = value['v63'].split(',');
          let v126 = value['v126'].split(',');
          let v252 = value['v252'].split(',');
          let indexValues = value['price'].split(',');
          v63.pop();
          v126.pop();
          v252.pop();
          for (let i = 0; i < v63.length; i++) {
            let v63ValuePair = v63[i].split(':');
            let v126ValuePair = v126[i].split(':');
            let v252ValuePair = v252[i].split(':');
            let indexValuePair = indexValues[i].split(':');
            if (v63[1] === 'nan') {
              continue;
            }
            chartData.labels.push(v63ValuePair[0]);
            chartData.v63.push(parseFloat(v63ValuePair[1]));
            chartData.v126.push(parseFloat(v126ValuePair[1]));
            chartData.v252.push(parseFloat(v252ValuePair[1]));
            chartData.indexValues.push(parseFloat(indexValuePair[1]));
          }
          for (let i = 0; i < CHARTPADDING; i++) {
            chartData.labels.push(' ');
          }
          let title = value['index'].replace('Term', ' Term').split('_');
          this.setState({data: [...this.state.data, {data : {
              labels: chartData.labels,
              datasets : [{label : indices[title[0]], 
                data: chartData.indexValues,
                borderWidth: 1.5,
                borderColor: 'rgba(34, 177, 76, 1)',
                backgroundColor: 'rgba(34, 177, 76, 0.5)',
                yAxisID: 'y',
                hidden: true},
                {label : ['v63'], 
                data: chartData.v63,
                borderWidth: 1.5,
                borderColor: 'rgba(231, 76, 60, 1)',
                backgroundColor: 'rgba(231, 76, 60, 0.5)',
                yAxisID: 'y1',
                hidden: true},
                {label : ['v126'], 
                data: chartData.v126,
                borderWidth: 1.5,
                borderColor: 'rgba(255, 140, 0, 1)',
                backgroundColor: 'rgba(255, 140, 0, 0.5)',
                yAxisID: 'y1',
                hidden: true},
                {label : ['v252'], 
                data: chartData.v252,
                borderWidth: 1.5,
                borderColor: 'rgba(241, 196, 15, 1)',
                backgroundColor: 'rgba(241, 196, 15, 0.5)',
                yAxisID: 'y1'}]
          }}]}, () => {});
        }
      }).catch(() => {
          return;
      });
    }

    render() {
      if (!this.props.currentUser.isAuthenticated) {
        return (<Redirect to={{ pathname: '/login' }} />);
      }
      if (this.props.currentUser.currentSubscriptionEndDate === TIER1) {
        return (<Redirect to={{ pathname: '/dashboard' }} />);
      }
      if (window.innerWidth <= 600) {
        options.maintainAspectRatio = false;
      } else {
        options.maintainAspectRatio = true;
      }
      let lines = [];
      for (let i = 0; i < this.state.data.length; i+=1) {
          lines.push(<div className='row'>
              <Line options={options} plugins={[plugin]} data={this.state.data[i].data} />
          </div>);
      }
      
      return (
          <div>
            {Object.keys(this.state.data).length !== 0 ? (
              <div class='multi-line-chart-wrapper'>
                {lines}
              </div>
            ) : (<div></div>)}
          </div>
      );
    }
}

export default Entropy;