import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: ''
        };
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSubmit = e => {
        e.preventDefault();
        const authType = 'login';
        this.props.onAuth(authType, this.state)
            .then(() => {
                this.props.history.push('/dashboard');
            })
            .catch((error) => {
                this.setState({errors: error}, () => {
                    return;
                });
            });
    }

    render() {
        if (this.props.currentUser.isAuthenticated) {
            return (<Redirect to={{ pathname: '/dashboard' }} />);
          }
        const { errors, history, removeError } = this.props;
        history.listen(() => {
            removeError();
        });

        return (
            <div className="login_container">
                <div className="image">
                    <h1>Welcome To Quantopia</h1>
                </div>
                <div className="content">
                    <form onSubmit={this.handleSubmit}>
                        <h1>Login</h1>
                        {/* {errors.message && (
                            <div className='alert alert-danger'>{errors.message}</div>
                        )} */}
                        <div className="form-group">
                            <label htmlFor="email">E-mail</label>
                            <br></br>
                            <input
                                autoComplete="off"
                                className="form-control"
                                id="email"
                                name="email"
                                type="email"
                                required
                                onChange={this.handleChange}
                            ></input>
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <br></br>
                            <input
                                autoComplete="off"
                                className="form-control"
                                id="password"
                                name="password"
                                type="password"
                                required
                                onChange={this.handleChange}
                            ></input>
                        </div>
                        <hr></hr>
                        <Link className="fp" to="/register">Don't Have An Account?</Link>
                        <hr></hr>
                        <Link className="fp" to="/reset-password">Forgot Password?</Link>
                        <hr></hr>
                        <div className="form-group">
                            <button
                                type="submit"
                                className="btn">
                                Login                                
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default Login;