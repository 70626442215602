import React from 'react';
import { Provider } from 'react-redux';
import { configureStore } from '../store';
import { BrowserRouter as Router } from 'react-router-dom';
import Main from './Main';
import { setAuthorizationToken, setCurrentUser } from '../store/actions/auth';

const store = configureStore();

if (localStorage.jwtToken) {
  setAuthorizationToken(localStorage.jwtToken);
  // prevent someone from tampering with key of jwtToken in localStorage
  try {
    store.dispatch(setCurrentUser(localStorage.jwtToken));
  } catch (err) {
    store.dispatch(setCurrentUser({}));
  }
}

const App = () => (
  <Provider store = {store}>
    <Router>
      <Main></Main>
    </Router>
  </Provider>
)

export default App;