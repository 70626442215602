import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <div>
                <footer>
                    <p>2022-2023 &#xa9; Peramko Inc. All Rights Reserved.</p>
                    <ul>
                        {/* <li><a href="https://discord.gg/VdcgSRES" target="_blank"><i class="fab fa-discord fa-2x"></i></a></li> */}
                        {/* <li><a href="https://www.youtube.com/@quantopiaai" target="_blank"><i class="fab fa-youtube-square fa-2x"></i></a></li> */}
                        {/* <li><a href="https://www.instagram.com/quantopia_ai" target="_blank"><i class="fab fa-instagram-square fa-2x"></i></a></li> */}
                        {/* <li><a href="https://twitter.com/QuantopiaAi" target="_blank"><i class="fab fa-twitter-square fa-2x"></i></a></li> */}
                        {/* <li><a href="https://www.tiktok.com/@quantopia_ai" target="_blank"><i class="fab fa-tiktok fa-2x"></i></a></li> */}
                    </ul>
                </footer>
            </div>
        );
    }
}

export default Footer;