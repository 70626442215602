import React, { Component } from 'react';
import Logo from '../images/icon128.png';
import Sample from '../images/sample.png';
import Secret from '../images/secret.png';
import Discord from '../images/discord.png';
import Course from '../images/course.png';

class Index extends Component {
    render() {
        return (
          <div></div>
          // <div className="index">
          //   <section id="how-it-works" class="section1">
          //     <div class="card">
          //       {/* <h2 id='herotitle' class="title">Trading Signals App + Discord Community. Predict Stock and Crypto Prices With Ease.&nbsp;</h2> */}
          //       <h2 id='herotitle' class="title">Predict Stock Market Movements Before They Happen. Quantitative models to predict US stock market movements. &nbsp;</h2>
          //       <br></br>
          //       <hr></hr>
          //       <ul class="grid4">
          //         <li>
          //           {/* <h4>Predictive Stock Market Models</h4> */}
          //           <img src={Sample}></img>
          //           <p>Quantitative models to predict stock market regimes. Identify when equity markets top and bottom out with a high degree of confidence. </p>
          //           <p>Models are updated twice daily.</p>
          //           <p>Custom models available upon request. Stocks indices and etfs, bonds, currencies, metals, commodities, real estate, and more. Contact us today.</p>
          //         </li>
          //         {/* <li>
          //           <h4>Join The Discord</h4>
          //           <img src={Discord}></img>
          //           <p>Daily US market updates on stocks, bonds, metals, forex, and crypto.</p>
          //           <p>Member chat and voice channels. Alpha leaks.</p>
          //         </li> */}
          //       </ul>
          //     </div>
          //   </section>
          //   <section id='pricing' class="section1">
          //     <div className='card'>
          //       <h3 class="title">Membership Pricing</h3>
          //       <ul class="grid2">
          //         <li>
          //           <i class="fas fa-hand-holding-usd fa-3x"></i>
          //           <hr></hr>
          //           <ul>
          //             <li><i class="fas fa-user-secret fa-2x"></i><p>Access to all 6 quantitative models.</p></li>
          //             {/* <li><i class="fas fa-server fa-2x"></i><p>Full member privileges in the Discord - Private trading group channels and market alpha.</p></li> */}
          //             <li><i class="fas fa-lock fa-2x"></i><p>Limited Spots Available - This is done in order to preserve the alpha of the strategy. You may gain a spot if a member relinquishes their subscription.</p></li>
          //           </ul>
          //           <a href="/membership-settings" class="gradientbtn">Become a Member - $249.99/MONTH (ALL SPOTS FILLED! Spots may re-open sometime in the future.)</a>
          //         </li>
          //       </ul>
          //     </div>
          //   </section>
          //   <section id='learnmore' class="section1">
          //     <div class='card'>
          //       <h3 class="title">Learn more</h3>
          //       <hr></hr>
          //       <ul class="grid2">
          //           <li>
          //               <img src={Logo} alt="Quantopia Trading"></img>
          //               {/* <h3>Follow Us On Social Media</h3> */}
          //               {/* <ul class="grid4"> */}
          //                   {/* <li><a href="https://discord.gg/VdcgSRES" target="_blank"><i class="fab fa-discord fa-2x"></i></a></li> */}
          //                   {/* <li><a href="https://www.youtube.com/@quantopiaai" target="_blank"><i class="fab fa-youtube-square fa-2x"></i></a></li> */}
          //                   {/* <li><a href="https://www.instagram.com/quantopia_ai" target="_blank"><i class="fab fa-instagram-square fa-2x"></i></a></li> */}
          //                   {/* <li><a href="https://twitter.com/QuantopiaAi" target="_blank"><i class="fab fa-twitter-square fa-2x"></i></a></li> */}
          //                   {/* <li><a href="https://www.tiktok.com/@quantopia_ai" target="_blank"><i class="fab fa-tiktok fa-2x"></i></a></li> */}
          //               {/* </ul> */}
          //           </li>
          //           <li>
          //               <h4>Useful Links</h4>
          //               <ul class="grid3">
          //                   <li><a href="/">Home</a></li>
          //                   <li><a href="/#how-it-works">How It Works</a></li>
          //                   <li><a href="/#pricing">Pricing</a></li>
          //                   <li><a href="/login">Sign Up/Sign In</a></li>
          //                   {/* <li><a href="/#about">About</a></li> */}
          //                   {/* <li><a href="https://discord.gg/VdcgSRES" target="_blank">Support</a></li> */}
          //                   <li><a href="mailto:info@quantopia.ai" target="_blank">Support</a></li>
          //               </ul>
          //           </li>
          //           <li>
          //               <h4>Other Links</h4>
          //               <ul class="grid3">
          //                 <li><a href="/privacy-policy">Privacy Policy</a></li>
          //                 <li><a href="/terms-of-service">Terms of Service</a></li>
          //               </ul>
          //           </li>
          //       </ul>
          //     </div>
          //   </section>
          // </div>
        );
    }
}

export default Index;