import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

ChartJS.defaults.color = "#ffffff";
ChartJS.defaults.plugins.legend.onHover = function() { 
  document.body.style.cursor = 'pointer'; 
};
ChartJS.defaults.plugins.legend.onLeave = function() { 
  document.body.style.cursor = 'unset'; 
};

const plugin = {
  id: 'customCanvasBackgroundColor',
  beforeDraw: (chart, args, options) => {
    const {ctx} = chart;
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = options.color || '#0';
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  }
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    tooltip: {
      mode: 'nearest',
      intersect: false
    },
    layout: {
      padding: 25
    },
    title: {
      display: true,
      text: 'US Index Quarterly Returns',
      color: 'white',
      font: {
        size: 25,
        family: 'Quicksand',
        lineHeight: 2.5,
      },
    },
    zoom: {
      zoom: {
        wheel: {
          enabled: true,
        },
        pinch: {
          enabled: true
        },
        mode: 'x',
      },
      pan: {
        enabled: true,
        mode: 'x',
      },
    }
  },
};

const TIER1 = '';

class QuarterlySeasonality extends Component {

    constructor(props) {
        super(props);
        this.state = {
          data : {}
        }
    }

    componentDidMount() {
      if (!this.props.currentUser.isAuthenticated || this.props.currentUser.currentSubscriptionEndDate === TIER1) {
        return;
      }
      this.props.apiCall('get', '/quarterlyseasonality', this.state)
      .then((data) => {
          let results = data[0].value.split(',');
          let labels = [];
          let values = [];
          results.pop();
          results.forEach(function(result) {
            let resultPair = result.split(':');
            labels.push(resultPair[0]);
            values.push(resultPair[1]);
          });
          this.setState({data: {
              labels,
              datasets : [{label : ['Average Returns (%) [Year 4 is the US Election Year]'], 
                data: values,
                backgroundColor: 'rgba(21, 0, 255, 0.75)'}]
            }
          }, () => {});
      }).catch(() => {
          return;
      });
    }

    render() {
      if (!this.props.currentUser.isAuthenticated) {
        return (<Redirect to={{ pathname: '/login' }} />);
      }
      if (this.props.currentUser.currentSubscriptionEndDate === TIER1) {
        return (<Redirect to={{ pathname: '/dashboard' }} />);
      }
      if (window.innerWidth <= 600) {
        options.maintainAspectRatio = false;
      } else {
        options.maintainAspectRatio = true;
      }
      return (
          <div class='single-bar-chart-wrapper'>
              {Object.keys(this.state.data).length !== 0 ? (
                <Bar options={options} plugins={[plugin]} data={this.state.data} />
              ) : (<div></div>)}
          </div>
      );
    }
}

export default QuarterlySeasonality;